export default [
  {
    width: 20,
    header: {
      type: 'slot',
      name: 'methodHeader',
    },
    cell: {
      type: 'slot',
      name: 'method',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'slot',
      name: 'type',
    },
  },
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Decimals',
    },
    cell: {
      type: 'slot',
      name: 'decimals',
    },
  },
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'Limits',
    },
    cell: {
      type: 'slot',
      name: 'limits',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Fee',
    },
    cell: {
      type: 'slot',
      name: 'fee',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Auto Fee',
    },
    cell: {
      type: 'slot',
      name: 'autofee',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Fee limits',
    },
    cell: {
      type: 'slot',
      name: 'feeLimits',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Crypto currency',
    },
    cell: {
      type: 'slot',
      name: 'cryptoCurrency',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Crypto token',
    },
    cell: {
      type: 'slot',
      name: 'cryptoToken',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
];

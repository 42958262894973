//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  props: {
    value: { type: Boolean, default: false },
    method: { type: Object, default: () => ({}) },
    type: { type: String },
    countries: { type: Array, default: () => [] },
  },

  data() {
    return {
      country: {},
      city: {
        countryId: 169,
      },
      countryId: 0,
      cityId: 0,
      currentToEdit: {
        name: '',
        aliases: '',
        sortId: null,
        iso2: '',
        iso3: '',
      },
      isAsideOpened: false,
      isEditVisible: true,
      aliasInput: '',
    };
  },

  computed: {
    ...mapState('paymentMethodsAndCurrenciesPaymentMethods', {
      countriesList: 'countries',
      citiesList: 'cities',
    }),

    show: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    modelAddableElementId: {
      get() {
        return this.type === 'strike' ? this.cityId : this.countryId;
      },
      set(value) {
        if (this.type === 'strike') {
          this.cityId = value;
        } else {
          this.countryId = value;
        }
      },
    },

    modelName: {
      get() {
        return this.type === 'strike' ? this.city.name : this.country.name;
      },
      set(value) {
        if (this.type === 'strike') {
          this.$set(this.city, 'name', value);
        } else {
          this.$set(this.country, 'name', value);
        }
      },
    },

    modelAliases: {
      get() {
        return this.type === 'strike' ? this.city.aliases : this.country.aliases;
      },
      set(value) {
        if (this.type === 'strike') {
          this.$set(this.city, 'aliases', value);
        } else {
          this.$set(this.country, 'aliases', value);
        }
      },
    },

    modelSortId: {
      get() {
        return this.type === 'strike' ? this.city.sortId : this.country.sortId;
      },
      set(value) {
        if (this.type === 'strike') {
          this.$set(this.city, 'sortId', value);
        } else {
          this.$set(this.country, 'sortId', value);
        }
      },
    },

    computedStyle() {
      const styles = [];

      if (!this.isEditVisible) {
        styles.push('visibility: hidden');
      }

      if (this.isCurrentToEditEmpty) {
        styles.push('display: flex; justify-content: center; align-items: center');
      } else {
        styles.push('padding: 8px');
      }

      return styles.join('; ');
    },

    isCurrentToEditEmpty() {
      return _.isEmpty(this.currentToEdit);
    },

    isEditDisabled() {
      if (this.$props.type === 'strike') {
        return !this.currentToEdit.name || !this.currentToEdit.aliases;
      }
      return !this.currentToEdit.name || !this.currentToEdit.aliases || !this.currentToEdit.iso2 || !this.currentToEdit.iso3;
    },

    isCreateDisabled() {
      if (this.$props.type === 'strike') {
        return !this.city.name || !this.city.aliases;
      }
      return !this.country.name || !this.country.aliases || !this.country.iso2 || !this.country.iso3;
    },

    getMaxWidth() {
      return this.isAsideOpened ? '1020px' : '462px';
    },

    getEditHeight() {
      return this.isEditVisible ? '527px' : '0px';
    },

    getCountries() {
      return this.$props.countries.filter((e) => {
        const cities = this.$props?.method?.data?.flatMap((item) => item.cities) || [];
        return !cities.some((city) => city.name === e.name);
      });
    },
  },

  methods: {
    updateAliases() {
      const aliasesArray = this.aliasInput.split(', ').map((alias) => alias.trim());
      const aliasesArrayToEdit = this.currentToEdit.aliases.split(', ').map((alias) => alias.trim());
      if (this.type === 'strike') {
        this.$set(this.city, 'aliases', aliasesArray);
        this.$set(this.currentToEdit, 'aliases', aliasesArrayToEdit);
      } else {
        this.$set(this.country, 'aliases', aliasesArray);
        this.$set(this.currentToEdit, 'aliases', aliasesArrayToEdit);
      }
    },

    cancelEditing() {
      this.currentToEdit = {};
      this.isAsideOpened = false;
    },

    getRowName(row) {
      return this.$props.type === 'strike' ? row?.cities[0]?.name : row?.country?.name;
    },

    addCountry() {
      this.$emit('add-country', this.$props.type === 'strike' ? this.cityId : this.countryId);
    },

    editElement(row) {
      const where = this.$props.type === 'strike' ? row.cities[0] : row.country;
      this.currentToEdit.name = where.name;
      this.currentToEdit.aliases = where.aliases;
      this.currentToEdit.sortId = where.sortId;
      this.currentToEdit.iso2 = where?.iso2;
      this.currentToEdit.iso3 = where?.iso3;
      this.currentToEdit.id = where?.id;
      this.isAsideOpened = true;
    },

    getTableElementName(row) {
      return this.$props.type === 'strike' ? row.name : row.country.name;
    },

    createCountry() {
      this.$emit('create-country', this.$props.type === 'strike' ? this.city : this.country);
    },

    editCountry() {
      const {
        id,
        name,
        aliases,
        sortId,
        iso2,
        iso3,
      } = this.currentToEdit;

      this.$emit('edit-country', {
        countryId: id,
        name,
        aliases,
        sortId,
        iso2,
        iso3,
      });
    },

    deleteCountry(id) {
      this.$emit('delete-country', id);
    },

    removeFromPaymentMethod(id) {
      this.$emit('remove-from-payment-method', id);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';
import Modal from './Modal.vue';

import tableConfig from './tableConfig';
import actionsConfig from './actionsConfig';

export default {
  components: {
    Modal,
  },

  props: {
    currencyId: { type: Number, default: 0 },
  },

  data() {
    return {
      tableConfig,
      actionsConfig,
      selection: [],
      loader: false,
      search: '',
      currentMethodId: 0,
      constant,
      editingMethod: {
        modal: false,
        data: {},
        isCreating: false,
      },
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('paymentsAndCurrenciesPaymentsExtra', ['page', 'pages', 'data', 'sort']),
    ...mapState('paymentsAndCurrenciesPayments', {
      currenciesData: 'data',
      cryptoCurrencies: 'cryptoCurrencies',
      cryptoTokens: 'cryptoTokens',
    }),

    preparedData() {
      const { data, search } = this;
      return data.filter((e) => {
        const transactionType = this.$utils.getCaption(
          constant.operations.TRANSACTION_TYPE_LIST,
          e.transactionType,
        );
        const transactionMethodType = this.$utils.getCaption(
          constant.operations.TRANSACTION_METHOD_TYPE_LIST,
          e.transactionMethodType,
        );
        const name = e.name || '';
        return (
          name.toLowerCase().includes(search.toLowerCase())
          || transactionType.toLowerCase().includes(search.toLowerCase())
          || transactionMethodType.toLowerCase().includes(search.toLowerCase())
        );
      });
    },

    currentMethod() {
      const { currentMethodId, data } = this;
      return data.find((e) => e.id === currentMethodId);
    },

    currentMethodCurrency() {
      const { currentMethod, currenciesData } = this;
      if (currentMethod) {
        const { currencyId } = currentMethod;
        return currenciesData.find((e) => e.id === currencyId);
      }
      return null;
    },
  },

  watch: {
    currencyId() {
      this.search = '';
      this.applyFilters();
    },
  },

  mounted() {
    this.applyFilters();
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('paymentsAndCurrenciesPaymentsExtra', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      createTransactionMethodAction: 'createTransactionMethod',
      editTransactionMethodAction: 'editTransactionMethod',
      deleteTransactionMethodAction: 'deleteTransactionMethod',
      setTransactionMethodIconAction: 'setTransactionMethodIcon',
    }),

    async loadData(payload = {}) {
      const { currencyId } = this;
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction({
        currencyIdList: currencyId ? [currencyId] : null,
        ...payload,
      });
      const { data } = this;
      this.currentMethodId = (data && data.length) ? data[0].id : 0;
      this.setGeneralProgress(false);
      this.loader = false;
    },

    checkIsLargeFloat(num) {
      return ((num).toString()).indexOf('e') > -1;
    },

    convertToNormalNumber(number, decimals) {
      return number.toFixed(decimals).replace(/\.?0+$/, '');
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    setCurrentMethod(data) {
      this.currentMethodId = data.id;
    },

    getCurrencyDecimalsById(id) {
      const { currenciesData } = this;
      const currency = currenciesData.find((e) => e.id === id);
      if (currency) {
        return `0.${'0'.repeat(currency.decimals > 1 ? currency.decimals - 1 : currency.decimals)}1`;
      }
      return null;
    },

    getCurrencyTitleById(id) {
      const { currenciesData } = this;
      const currency = currenciesData.find((e) => e.id === id);
      if (currency) {
        return currency.title;
      }
      return null;
    },

    getCryptoCurrencyTitleById(id) {
      const { cryptoCurrencies } = this;
      const currency = cryptoCurrencies.find((e) => e.id === id);
      if (currency) {
        return currency.title;
      }
      return null;
    },

    getCryptoTokenTitleById(id) {
      const { cryptoTokens } = this;
      const currency = cryptoTokens.find((e) => e.id === id);
      if (currency) {
        return currency.title;
      }
      return null;
    },

    editMethod(row) {
      const typeOfDecimals = row.decimalsEnabled
        ? row.decimals : this.getCurrencyDecimalsById(row.currencyId);
      this.editingMethod.modal = true;
      const editRow = { ...row };
      if (editRow.minAmount === 0) {
        editRow.minAmount = this.getCurrencyDecimalsById(row.currencyId);
      } else {
        editRow.minAmount = this.checkIsLargeFloat(editRow.minAmount)
          ? editRow.minAmount.toFixed(typeOfDecimals) : editRow.minAmount;
      }
      this.editingMethod.data = { ...editRow };
      this.editingMethod.isCreating = false;
    },

    createMethod() {
      this.editingMethod.modal = true;
      this.editingMethod.data = {};
      this.editingMethod.isCreating = true;
    },

    applyEdit({ input, icon }) {
      const { isCreating } = this.editingMethod;
      if (+input.minAmount > +input.maxAmount) {
        this.setErrorNotification('Maximum limit cant be less than minimum amount');
      } else {
        this.confirmAction({
          title: isCreating
            ? 'Are you sure you want to create transaction method?'
            : 'Are you sure you want to edit transaction method?',
          callback: async () => {
            const { currencyId } = this;
            this.setGeneralProgress(true);
            this.loader = true;
            try {
              if (isCreating) {
                await this.createTransactionMethodAction({
                  ...input,
                  id: null,
                  autoFee: Number(input.autoFee),
                });
              } else {
                await this.editTransactionMethodAction({
                  ...input,
                  autoFee: Number(input.autoFee),
                });
              }
              if (icon) {
                const formData = new FormData();
                formData.append('Icon', icon, icon.name);
                await this.setTransactionMethodIconAction({
                  params: {
                    TransactionMethodId: input.id,
                  },
                  data: formData,
                });
              }
              this.editingMethod.modal = false;
              await this.loadDataAction({
                currencyIdList: currencyId ? [currencyId] : null,
              });
              if (isCreating) {
                this.setSuccessNotification('Transaction method created');
              } else {
                this.setSuccessNotification('Transaction method edited');
              }
            } catch (error) {
              this.setErrorNotification(error.message);
            }
            this.setGeneralProgress(false);
            this.loader = false;
          },
        });
      }
    },

    applyRemove() {
      this.confirmAction({
        title: 'Are you sure you want to delete transaction method?',
        callback: async () => {
          const { currencyId } = this;
          const { id } = this.editingMethod.data;
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            await this.deleteTransactionMethodAction({
              transactionMethodId: id,
            });
            this.editingMethod.modal = false;
            await this.loadDataAction({
              currencyIdList: currencyId ? [currencyId] : null,
            });
            this.setSuccessNotification('Transaction method deleted');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },
  },
};

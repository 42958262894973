//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    isCreating: { type: Boolean, default: false },
  },

  data() {
    return {
      constant,
      input: {},
      icon: null,
    };
  },

  computed: {
    ...mapState('currencies', ['currencies']),
    ...mapState('paymentsAndCurrenciesPayments', [
      'cryptoCurrencies',
      'cryptoTokens',
    ]),
    ...mapGetters('currencies', ['getCurrencyById']),

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    defaultInput() {
      return {
        name: '',
        currencyId: 0,
        cryptoCurrencyId: null,
        cryptoTokenId: null,
        transactionMethodType: 0,
        transactionType: 0,
        maxAmount: '0',
        percFee: '0',
        absFee: '0',
        minFee: '0',
        maxFee: '0',
        decimalsEnabled: false,
        decimals: '0',
        sortId: '0',
        enabled: true,
        additionalField: '',
        additionalFieldMultiline: false,
        rounding: '0',
        isManual: true,
        manualLimit: '0',
        manualCheckTime: '0',
      };
    },

    cryptoTokensFiltered() {
      const { cryptoTokens } = this;
      const { cryptoCurrencyId } = this.input;
      return cryptoTokens.filter((e) => {
        return e.cryptoCurrencyId === cryptoCurrencyId;
      });
    },
  },

  watch: {
    value(val) {
      if (val) {
        const { isCreating, defaultInput, data } = this;
        this.icon = null;
        if (isCreating) {
          this.$set(this, 'input', defaultInput);
        } else {
          this.$set(this, 'input', data);
        }
      }
    },
  },

  methods: {
    remove() {
      this.$emit('remove');
    },

    apply() {
      const { input, icon } = this;
      this.$emit('apply', { input, icon });
    },

    getCurrencyDecimals(value) {
      this.input.minAmount = `0,${'0'.repeat(this.getCurrencyById(value).decimals)}1`;
    },
  },
};

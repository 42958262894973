export default [
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'slot',
      name: 'id',
    },
  },
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'Name',
    },
    cell: {
      type: 'slot',
      name: 'name',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Status Wallet',
    },
    cell: {
      type: 'slot',
      name: 'statusWallet',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Status P2P',
    },
    cell: {
      type: 'slot',
      name: 'statusP2P',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Category',
    },
    cell: {
      type: 'slot',
      name: 'category',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Sort id',
      sort: 'sortid',
    },
    cell: {
      type: 'text',
      value: (e) => e.sortId,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Fields',
    },
    cell: {
      type: 'slot',
      name: 'fields',
    },
  },
  {
    width: 34,
    header: {
      type: 'slot',
      name: 'countryHeader',
    },
    cell: {
      type: 'slot',
      name: 'countries',
    },
  },
];
